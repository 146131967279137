<template>
  <div class="gyroAccess is-flex is-flex-direction-column">
    <slot :DeviceOrientationEnabled="DeviceOrientationEnabled" :dataStarted="dataStarted" :callibrated="callibrated"
      :alpha="alpha" :beta="beta" :gamma="gamma" :x="coords.x" :y="coords.y" :z="coords.z" :noGyro="noGyro">
    </slot>
  </div>
</template>

<script>
const utils = require("./utils");

export default {
  data() {
    return {
      callibrated: false,
      DeviceOrientationEnabled: false,
      dataStarted: false,
      noGyro: true,
      alpha: 0,
      beta: 0,
      gamma: 0,
      offsetAlpha: 0,
      offsetBeta: 0,
      offsetGamma: 0
    }
  },
  props: {
    range: {
      type: Number,
      required: false,
      default: 20, // Range of deegress to map into 0~1
    },
  },
  methods: {
    // ShowPreRequestDialog(){
    //   this.$buefy.dialog.alert({
    //       message: 'To use this experience we need to ask your permission to use the gyroscope',
    //       confirmText: 'I accept',
    //       type: 'is-success',
    //       onConfirm: () => this.RequestGyro()
    //   })
    // },
    // DeviceOrientationEvent es el gyro
    // https://developer.mozilla.org/en-US/docs/Web/API/DeviceOrientationEvent
    // Provee un evento que devuelve alpha, beta y gamma
    RequestGyro() {
      console.log("RequestGyro access");
      if (this.DeviceOrientationsNeedsRequest()) {
        console.log("requesting permission");
        DeviceOrientationEvent.requestPermission()
          .then(permissionState => {
            if (permissionState === 'granted') {
              // window.addEventListener('deviceorientation', (e) => { this.HandleGyroData(e) })
              window.addEventListener('deviceorientation', this.HandleGyroData)
            } else {
              // Permission denied
            }
          })
          .catch(console.error);
      } else {
        console.log("permission not necessary");
        // handle regular non iOS 13+ devices
        // window.addEventListener('deviceorientation', (e) => { this.HandleGyroData(e) });
        window.addEventListener('deviceorientation', this.HandleGyroData)
      }
    },
    HandleGyroData(evt) {
      // console.log(evt)
      this.dataStarted = true;
      this.alpha = evt.alpha
      this.beta = evt.beta
      this.gamma = evt.gamma
    },
    Callibrate() {
      console.log("Callibrated")
      this.offsetAlpha = this.alpha;
      this.offsetBeta = this.beta;
      this.offsetGamma = this.gama;
      this.callibrated = true;
      this.$emit('callibrated')
    },
    DeviceOrientationsNeedsRequest() {
      return typeof DeviceOrientationEvent !== 'undefined' && typeof DeviceOrientationEvent.requestPermission === 'function'
    },
  },
  computed: {
    coords() {
      let x = this.alpha - this.offsetAlpha;
      let y = this.beta - this.offsetBeta;
      let z = this.gamma - this.offsetGamma;

      if (x > 180)  x = x - 360

      x += this.range;
      x /= this.range * 2;
      x = 1 - utils.clamp(x, 0, 1);
      y = utils.map(y, -this.range, this.range, 1, 0)
      z = utils.map(z, -this.range, this.range, 1, 0)
      return { x, y, z }
    }
  },
  watch: {
    coords() {
      // console.log("coords changed")
      // if (!this.callibrated) return;
      // TODO cooldown
      // Emits a custom event *moved* to parent component
      this.$emit('moved', {
        coords: this.coords,
        alpha: this.alpha,
        beta: this.beta,
        gamma: this.gamma
      })
    },
    dataStarted() {
      this.noGyro = !this.DeviceOrientationEnabled && !this.dataStarted
    },
    DeviceOrientationEnabled() {
      this.Network = !this.DeviceOrientationEnabled && !this.dataStarted
    }
  },
  mounted() {
    // if (this.DeviceOrientationsNeedsRequest()) {
    // this.ShowPreRequestDialog()
    // }
  },
  beforeDestroy() {
    // remove listener
    window.removeEventListener("deviceorientation", this.HandleGyroData);
  }
};
</script>